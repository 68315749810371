<template>
    <div class="container">
        <div class="header">
            <div class="logo"><img src="../assets/images/logo.png" alt=""></div>
            <div class="header-nav">
                <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
                    <el-menu-item index="1">首页</el-menu-item>
                    <el-menu-item index="2">平台介绍</el-menu-item>
                    <el-submenu index="3" default-openeds>
                        <template slot="title">外贸营销平台工具</template>
                        <el-menu-item index="3-1">新闻咨询新闻咨</el-menu-item>
                        <el-menu-item index="3-1">新闻咨闻咨</el-menu-item>
                        <el-menu-item index="3-1">新闻咨询新闻</el-menu-item>
                        <el-menu-item index="3-1">新闻咨询新闻咨</el-menu-item>
                        <el-menu-item index="3-1">新闻咨询新闻咨</el-menu-item>
                        <el-menu-item index="3-1">新闻咨闻咨</el-menu-item>
                        <el-menu-item index="3-1">新闻咨询新闻</el-menu-item>
                        <el-menu-item index="3-1">新闻咨询新闻咨</el-menu-item>
                    </el-submenu>
                    <el-submenu index="4">
                        <template slot="title">外贸综合服务</template>
                        <el-menu-item index="4-1">选项1</el-menu-item>
                    </el-submenu>
                    <el-submenu index="5">
                        <template slot="title">产业品牌出海</template>
                        <el-menu-item index="5-1">选项1</el-menu-item>
                    </el-submenu>
                    <el-submenu index="6">
                        <template slot="title">海外资源</template>
                        <el-menu-item index="6-1">选项1</el-menu-item>
                    </el-submenu>
                    <el-submenu index="7">
                        <template slot="title">本地商务服务</template>
                        <el-menu-item index="7-1">选项1</el-menu-item>
                    </el-submenu>
                    <el-menu-item index="8">合作伙伴</el-menu-item>
                    <el-menu-item index="9">联系我们</el-menu-item>
                    <!-- <el-menu-item index="3" disabled>消息中心</el-menu-item>
                    <el-menu-item index="4"><a href="https://www.ele.me" target="_blank">订单管理</a></el-menu-item> -->
                </el-menu>
            </div>        
            <div class="header-search">
                <div class="header-search-img">
                    <img src="../assets/images/search.png" alt="">
                    <div>搜索</div>
                </div>
                <div class="header-search-img">
                    <img src="../assets/images/my.png" alt="">
                    <div>登录</div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
    export default {
        name:'commonHeader',
        data() {
            return {
                activeIndex: '1',
            };
        },
        methods: {
            handleSelect(key, keyPath) {
                console.log(key, keyPath);
            }
        }
    }
</script>
<style lang="less">
// .el-menu--horizontal>.el-menu--popup-bottom-start{
//     max-width: 500px!important;
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
//     padding:15px 10px;
// }
// .el-menu--horizontal>.el-menu--popup-bottom-start>.el-menu-item{
//     font-size: 16px;
//     width: 33.3%;
//     text-align: center;
//     padding: 5px 0 !important;
//     height: auto !important;
// }

// .el-menu--horizontal>.el-menu--popup-bottom-start>.el-menu-item:hover{
//     background: #409EFF;
//     color: #fff !important;
//     transition: all .1s !important;
// }
    
</style>

<style lang="less" scoped>
/deep/ .el-menu.el-menu--horizontal{border-bottom: none;}
/deep/ .el-menu--horizontal>.el-submenu .el-submenu__title, 
/deep/ .el-menu--horizontal>.el-menu-item{
    font-size: 16px;
    color: #262626;
    height: 66px;
    line-height: 66px;
    border: none;
}
/deep/ .el-menu-item, 
/deep/ .el-submenu__title{
    padding: 0  15px;
}
/deep/ .el-menu--horizontal>.el-menu-item.is-active{color: #409EFF;font-size: 16px;}
/deep/ .el-menu--horizontal>.el-submenu .el-submenu__icon-arrow{margin-left: 2px;font-size: 18px;color: #707071;}













.container{background: #fff;}
.header{width: 1350px; display: flex;justify-content: space-between;margin:0 auto;padding: 4px 0;color: #262626;}
.logo{
    width: 66px;
    img{
        width: 100%;
    }
}
.header-search{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-search-img{
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        img{width: 19px;}
        div{font-size: 15px;margin: 0 16px 0 9px;}
    }
}

</style>