import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import {
  MessageBox,Message
} from 'element-ui'

// 解决vueRouter 跳转相同路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject){
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter)

const routes = [{
        path: '/',
		name: 'Main',
		component: ()=>import("../views/layout/main.vue"),//路由懒加载解决首屏加载慢，性能优化,
		redirect: '/index',
		children: [{
                path: '/index',
                name: 'index',
                component:()=>import("../views/index/index.vue"),
                meta: {
                    title: '首页',
                    isKeep:true,
                    needLogin:false,
                }
            },
            {
                path: '/platform',
                name: 'platform',
                component:()=>import("../views/platform.vue"),
                meta: {
                    title: '平台介绍',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/recom',
                name: 'recom',
                component:()=>import("../views/yingxiao_pingtai/recom.vue"),
                meta: {
                    title: '推荐详情',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/enter',
                name: 'enter',
                component:()=>import("../views/yingxiao_pingtai/enter.vue"),
                meta: {
                    title: '申请入驻',
                    isKeep:false,
                    needLogin:true,
                }
            },
            {
                path: '/kjdspt',
                name: 'kjdspt',
                component:()=>import("../views/yingxiao_pingtai/1/kjdspt.vue"),
                meta: {
                    title: '跨境电商平台',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/dianshang_introduce',
                name: 'dianshang_introduce',
                component:()=>import("../views/yingxiao_pingtai/1/dianshang_introduce.vue"),
                meta: {
                    title: '电商平台介绍',
                    isKeep:false,
                    needLogin:true,
                }
            },
            {
                path: '/state_pingtai',
                name: 'state_pingtai',
                component:()=>import("../views/yingxiao_pingtai/1/state_pingtai.vue"),
                meta: {
                    title: '各国电商平台',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/pingtai_list',
                name: 'pingtai_list',
                component:()=>import("../views/yingxiao_pingtai/1/pingtai_list.vue"),
                meta: {
                    title: '国家电商平台列表',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/wmyxgj',
                name: 'wmyxgj',
                component:()=>import("../views/yingxiao_pingtai/2/wmyxgj.vue"),
                meta: {
                    title: '外贸营销工具',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/tool',
                name: 'tool',
                component:()=>import("../views/yingxiao_pingtai/2/tool.vue"),
                meta: {
                    title: '外贸营销工具',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/tool_introduce',
                name: 'tool_introduce',
                component:()=>import("../views/yingxiao_pingtai/2/tool_introduce.vue"),
                meta: {
                    title: '工具介绍',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/hwcyx',
                name: 'hwcyx',
                component:()=>import("../views/yingxiao_pingtai/3/hwcyx.vue"),
                meta: {
                    title: '海外仓营销',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/haiwai_introduce',
                name: 'haiwai_introduce',
                component:()=>import("../views/yingxiao_pingtai/3/haiwai_introduce.vue"),
                meta: {
                    title: '海外仓营销(海外仓介绍)',
                    isKeep:false,
                    needLogin:true,
                }
            },
            {
                path: '/haiwai_join',
                name: 'haiwai_join',
                component:()=>import("../views/yingxiao_pingtai/3/haiwai_join.vue"),
                meta: {
                    title: '海外仓营销(海外仓加盟)',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/haiwai_state',
                name: 'haiwai_state',
                component:()=>import("../views/yingxiao_pingtai/3/haiwai_state.vue"),
                meta: {
                    title: '海外仓营销(国家列表)',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/haiwai_list',
                name: 'haiwai_list',
                component:()=>import("../views/yingxiao_pingtai/3/haiwai_list.vue"),
                meta: {
                    title: '海外仓营销(国家海外仓)',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/jksc',
                name: 'jksc',
                component:()=>import("../views/yingxiao_pingtai/4/jksc.vue"),
                meta: {
                    title: '进口商城',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/shop_list',
                name: 'shop_list',
                component:()=>import("../views/yingxiao_pingtai/4/shop_list.vue"),
                meta: {
                    title: '进口商城(国家区域)',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/shop_state',
                name: 'shop_state',
                component:()=>import("../views/yingxiao_pingtai/4/shop_state.vue"),
                meta: {
                    title: '进口商城(各国商城列表)',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/shop_introduce',
                name: 'shop_introduce',
                component:()=>import("../views/yingxiao_pingtai/4/shop_introduce.vue"),
                meta: {
                    title: '进口商城(商城介绍)',
                    isKeep:false,
                    needLogin:true,
                }
            },
            {
                path: '/zbcg',
                name: 'zbcg',
                component:()=>import("../views/yingxiao_pingtai/5/zbcg.vue"),
                meta: {
                    title: '国际招标与采购信息',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/msgs_list',
                name: 'msgs_list',
                component:()=>import("../views/yingxiao_pingtai/5/msgs_list.vue"),
                meta: {
                    title: '国际招标与采购信息(列表)',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/msgs_details',
                name: 'msgs_details',
                component:()=>import("../views/yingxiao_pingtai/5/msgs_details.vue"),
                meta: {
                    title: '国际招标与采购信息(详情)',
                    isKeep:false,
                    needLogin:true,
                }
            },
            {
                path: '/gjhz',
                name: 'gjhz',
                component:()=>import("../views/yingxiao_pingtai/6/gjhz.vue"),
                meta: {
                    title: '国际会展',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/huizhan_list',
                name: 'huizhan_list',
                component:()=>import("../views/yingxiao_pingtai/6/huizhan_list.vue"),
                meta: {
                    title: '国际会展(国家区域)',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/huizhan_state',
                name: 'huizhan_state',
                component:()=>import("../views/yingxiao_pingtai/6/huizhan_state.vue"),
                meta: {
                    title: '国际会展(各国会展列表)',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/huizhan_introduce',
                name: 'huizhan_introduce',
                component:()=>import("../views/yingxiao_pingtai/6/huizhan_introduce.vue"),
                meta: {
                    title: '国际会展(会展介绍)',
                    isKeep:false,
                    needLogin:true,
                }
            },
            {
                path: '/gqdj',
                name: 'gqdj',
                component:()=>import("../views/yingxiao_pingtai/7/gqdj.vue"),
                meta: {
                    title: '供求对接',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/supply',
                name: 'supply',
                component:()=>import("../views/yingxiao_pingtai/7/supply.vue"),
                meta: {
                    title: '供求对接',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/supply_details',
                name: 'supply_details',
                component:()=>import("../views/yingxiao_pingtai/7/supply_details.vue"),
                meta: {
                    title: '供求对接',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/supply_list',
                name: 'supply_list',
                component:()=>import("../views/yingxiao_pingtai/7/supply_list.vue"),
                meta: {
                    title: '供求对接',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/supply_state',
                name: 'supply_state',
                component:()=>import("../views/yingxiao_pingtai/7/supply_state.vue"),
                meta: {
                    title: '供求对接(国家区域)',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/supply_join',
                name: 'supply_join',
                component:()=>import("../views/yingxiao_pingtai/7/supply_join.vue"),
                meta: {
                    title: '发布供求易货信息',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/gwkhxxk',
                name: 'gwkhxxk',
                component:()=>import("../views/yingxiao_pingtai/8/gwkhxxk.vue"),
                meta: {
                    title: '国外客户信息库',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/msg_state',
                name: 'msg_state',
                component:()=>import("../views/yingxiao_pingtai/8/msg_state.vue"),
                meta: {
                    title: '国外客户信息库(国家列表)',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/msg_list',
                name: 'msg_list',
                component:()=>import("../views/yingxiao_pingtai/8/msg_list.vue"),
                meta: {
                    title: '国外客户信息库(国家信息库)',
                    isKeep:false,
                    needLogin:true,
                }
            },
            {
                path: '/wmzhfw',
                name: 'wmzhfw',
                component:()=>import("../views/waimao_fuwu/wmzhfw.vue"),
                meta: {
                    title: '外贸综合服务',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/service_list',
                name: 'service_list',
                component:()=>import("../views/waimao_fuwu/service_list.vue"),
                meta: {
                    title: '外贸综合服务(列表)',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/service_details',
                name: 'service_details',
                component:()=>import("../views/waimao_fuwu/service_details.vue"),
                meta: {
                    title: '外贸综合服务(详情)',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/cyppch',
                name: 'cyppch',
                component:()=>import("../views/pinpai_chuhai/cyppch.vue"),
                meta: {
                    title: '产业品牌出海',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/pinpai_list',
                name: 'pinpai_list',
                component:()=>import("../views/pinpai_chuhai/pinpai_list.vue"),
                meta: {
                    title: '产业品牌出海(行业列表)',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/pinpai_details',
                name: 'pinpai_details',
                component:()=>import("../views/pinpai_chuhai/pinpai_details.vue"),
                meta: {
                    title: '产业品牌出海(详情)',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/pinpai_join',
                name: 'pinpai_join',
                component:()=>import("../views/pinpai_chuhai/pinpai_join.vue"),
                meta: {
                    title: '产业品牌出海(会员在线提交表单)',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/hwzy',
                name: 'hwzy',
                component:()=>import("../views/haiwai_ziyuan/hwzy.vue"),
                meta: {
                    title: '海外本地服务资源',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/ziyuan_state',
                name: 'ziyuan_state',
                component:()=>import("../views/haiwai_ziyuan/ziyuan_state.vue"),
                meta: {
                    title: '海外本地服务资源(国家列表)',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/ziyuan_list',
                name: 'ziyuan_list',
                component:()=>import("../views/haiwai_ziyuan/ziyuan_list.vue"),
                meta: {
                    title: '海外本地服务资源(国家资源)',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/ziyuan_liebiao',
                name: 'ziyuan_liebiao',
                component:()=>import("../views/haiwai_ziyuan/ziyuan_liebiao.vue"),
                meta: {
                    title: '海外本地服务资源(国家资源)',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/ziyuan_introduce',
                name: 'ziyuan_introduce',
                component:()=>import("../views/haiwai_ziyuan/ziyuan_introduce.vue"),
                meta: {
                    title: '海外本地服务资源(资源介绍)',
                    isKeep:false,
                    needLogin:true,
                }
            },
            {
                path: '/bdswfw',
                name: 'bdswfw',
                component:()=>import("../views/shangwu_fuwu/bdswfw.vue"),
                meta: {
                    title: '本地商务服务',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/shangwu_list',
                name: 'shangwu_list',
                component:()=>import("../views/shangwu_fuwu/shangwu_list.vue"),
                meta: {
                    title: '本地商务服务(列表)',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/shangwu_details',
                name: 'shangwu_details',
                component:()=>import("../views/shangwu_fuwu/shangwu_details.vue"),
                meta: {
                    title: '本地商务服务(详情)',
                    isKeep:false,
                    needLogin:false,
                }
            },            
            {
                path: '/partner',
                name: 'partner',
                component:()=>import("../views/hezuo_huoban/partner.vue"),
                meta: {
                    title: '合作伙伴',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/partner_liebiao',
                name: 'partner_liebiao',
                component:()=>import("../views/hezuo_huoban/partner_liebiao.vue"),
                meta: {
                    title: '合作伙伴',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/partner_list',
                name: 'partner_list',
                component:()=>import("../views/hezuo_huoban/partner_list.vue"),
                meta: {
                    title: '合作伙伴',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/partner_item',
                name: 'partner_item',
                component:()=>import("../views/hezuo_huoban/partner_item.vue"),
                meta: {
                    title: '合作伙伴',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/partner_details',
                name: 'partner_details',
                component:()=>import("../views/hezuo_huoban/partner_details.vue"),
                meta: {
                    title: '合作伙伴详情',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/contact_us',
                name: 'contact_us',
                component:()=>import("../views/contact_us.vue"),
                meta: {
                    title: '联系我们',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/search_list',
                name: 'search_list',
                component:()=>import("../views/search_list.vue"),
                meta: {
                    title: '搜索结果',
                    isKeep:false,
                    needLogin:false,
                }
            },
            {
                path: '/search_details',
                name: 'search_details',
                component:()=>import("../views/search_details.vue"),
                meta: {
                    title: '搜索详情',
                    isKeep:false,
                    needLogin:false,
                }
            }
        ],
    },
    {
        path:'/login',
        name:'login',
        component: ()=>import("../views/login.vue"),
        meta: {
            title: '登录',
            // isKeep:false,
            // needLogin:false,
        }
    },
    {
        path:'/forget',
        name:'forget',
        component: ()=>import("../views/forget.vue"),
        meta: {
            title: '忘记密码',
            // isKeep:false,
            // needLogin:false,
        }
    },
    { 
        path:'/register',
        name:'register',
        component: ()=>import("../views/register.vue"),
        meta: {
            title: '注册',
            // isKeep:false,
            // needLogin:false,
        }
    },
    { 
        path:'/member',
        name:'member',
        component: ()=>import("../views/member.vue"),
        meta: {
            title: '会员付费升级',
            // isKeep:false,
            // needLogin:false,
        }
    },
    { 
        path:'/statement',
        name:'statement',
        component: ()=>import("../views/statement.vue"),
        meta: {
            title: '新丝路外服',
            // isKeep:false,
            // needLogin:false,
        }
    }
]


const scrollBehavior = (to, from, savedPosition) => {
	if (savedPosition) {
		return savedPosition
	} else {
		return {
			x: 0,
			y: 0
		}
	}
}

const router = new VueRouter({
  mode: 'history',//1、hash哈希：有#号。2、history历史：没有#号
  base: process.env.BASE_URL,
  routes,
  scrollBehavior
})

router.beforeEach((to,from,next)=>{
    // if (to.meta.title) {
    //     /* 路由发生变化修改页面title */
    //     document.title = to.meta.title
    // }
    let token = window.localStorage.getItem('token')
	if(token){
		store.commit('setLoginStatus',true)
	}
    if (to.meta.needLogin){
        if (store.state.isLogin){
            next();
        } else {            
            MessageBox.confirm('请您先登录注册会员','温馨提示', {
                confirmButtonText: '去登录',
                }).then(() => {
                    next('/login');
                }).catch(() => {
                    Message({
                        type:'info',
                        message:'请登录后再操作',
                        duration:1500
                    })
            });
        }
    } else {
        next();
    }

    
	
	// if(to.meta.needLogin && !store.state.isLogin){
	// 	Message({
	// 		type:'error',
	// 		message:'请登录后再操作',
	// 		duration:1500
	// 	})
	// 	next(to.meta.needLogin ? '/login' : to.fullPath);
	// }else{
	// 	next();
	// }
});


router.afterEach((to, from) => {
    // console.log(to, from);
    store.commit('changeRoute', to)
}) 

export default router
