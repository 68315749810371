<template>
  <div id="app">
    <router-view/>
    <!-- <headercom></headercom> -->
  </div>
</template>
<script>
import headercom from './components/commonHeader';
    export default {
      name: "App",
      data(){
        return {

        }
      },
      components:{
        headercom

      },
      created() {

      },
      methods:{
        
      }
    };
</script>

<style lang="less">
  /* html,body{background: #F4F4F4;} */
  * {
    margin: 0;
    padding: 0;
  }
  // 禁止鼠标选中
  *{ 
  -webkit-touch-callout:none; /*系统默认菜单被禁用*/
  -webkit-user-select:none; /*webkit浏览器*/
  -khtml-user-select:none; /*早期浏览器*/
  -moz-user-select:none;/*火狐*/
  -ms-user-select:none; /*IE10*/
  user-select:none;
 }
  ul li {
    list-style: none;
  }
  a {
    text-decoration: none;
  }
  #app {
    font-family: "Microsoft YaHei";
  }
  img{max-width: 100%;}
  .w-1350{width: 1600px;}
  .w-1300{width: 1600px;}

  .center-text{
      display: flex;align-items: center;margin-top:45px;
      p{width: 3px;height: 25px;background: #0071DB;}
      span{font-size: 20px;color: #000000;margin-left: 10px;}
  }

  .BMap_cpyCtrl
	{
		display:none; 
	}
	.anchorBL{
		display:none !important; 
	}
</style>
