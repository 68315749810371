import Vue from 'vue'
import Vuex from 'vuex'

import router from '../router'
// import {getUserInfo} from "../api/index.js"
import {	Message} from 'element-ui';


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    routeIndex: '',
    routeId:'',
    wid:'',
    zh_id:0,

    isLogin:false,//是否登录
  },
  mutations: {
    changeRoute(state, val) {
      // console.log(val);
      state.routeIndex = val.name;
      if(val.query.hzy_id){
        state.routeId = val.query.hzy_id;
      }   
      if(val.query.wid){
        state.wid = val.query.wid;
      } 
      // console.log(val.query.zh_id) 
      if(val.query.zh_id){
        state.zh_id = val.query.zh_id;
        // if(window.localStorage.getItem('zh_id')){
        //   if(window.localStorage.getItem('zh_id')!=val.query.zh_id){
        //     window.localStorage.removeItem('zh_id')
        //     localStorage.setItem('zh_id',val.query.zh_id)
        //     state.zh_id = window.localStorage.getItem('zh_id')
        //   }else{
        //     state.zh_id = window.localStorage.getItem('zh_id')
        //   }
        // }else{
        //   localStorage.setItem('zh_id',val.query.zh_id)
        //   state.zh_id = window.localStorage.getItem('zh_id')
        // }        
      }
      // console.log(window.localStorage.getItem('zh_id'))
    },
    //设置登陆状态
		setLoginStatus(state, status = true) {
			state.isLogin = status
		},
  },
  actions: {
    //退出
		vuex_out(context){
			//退出登陆
			Message({
				type: 'success',
				message: '退出成功',
				duration: 1500
			})			
			window.localStorage.removeItem('token')
      window.localStorage.removeItem('is_pay')
			context.commit('setLoginStatus', false)
			router.replace({
				path:'/'
			})
		},
  },
  modules: {
  }
})